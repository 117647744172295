import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";

import classNames from "classnames";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import axios from "axios";

import {
  HydraTable,
  Heading,
  TextFilter,
  DateRangeFilter,
  SelectFilter,
  StyledCheckbox,
  TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

import { roleValues } from "@peracto/peracto-user";
import { LoginAsUserModal } from "./LoginAsUserModal";

export const UserList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({
    roles: true,
    createdAt: true,
    lastLoggedInAt: true,
    telephone: false,
    isExcludedFromOmnirewards: true,
    lkqUserId: true,
  });

  const [creatingUserLogin, setCreatingUserLogin] = useState(null);
  const [showUserLoginModal, setShowUserLoginModal] = useState(false);
  const [userLoginModalFields, setUserLoginModalFields] = useState({
    email: "",
    password: "",
  });

  const { client } = useClient();

  const config = useConfig();
  const API_URL = config.get("api");

  const formatDate = config.get("user_format_date", config.get("format_date"));
  const { user } = config.get("features", {});

  const onCreateLoginAsUser = async (id) => {
    setCreatingUserLogin(id);

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const { data } = await axios.put(
        `${API_URL}/users/login-as-user/${id}`,
        {},
        {
          headers,
        }
      );

      setUserLoginModalFields({
        email: data.email || "",
        password: data.password || "",
      });

      setShowUserLoginModal(true);
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    } finally {
      setCreatingUserLogin(null);
    }
  };

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <StyledCheckbox {...row.getToggleRowSelectedProps()} />
        ),
        selectable: false,
      },
      {
        id: "avatar",
        Cell: ({ row }) => {
          return (
            <img
              alt={`${row.values.firstName} ${row.values.lastName}`}
              src={`https://eu.ui-avatars.com/api/?name=${row.values.firstName}+${row.values.lastName}&background=ededed&&color=666&rounded=true`}
            ></img>
          );
        },
        selectable: false,
      },
      {
        Header: "First Name",
        accessor: "firstName",
        sortType: "basic",
        Filter: (props) => <TextFilter label="First Name" {...props} />,
        selectable: false,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        sortType: "basic",
        Filter: (props) => <TextFilter label="Last Name" {...props} />,
        selectable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        Filter: (props) => <TextFilter label="Email" type="email" {...props} />,
        Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,

        selectable: false,
      },
      {
        Header: "Roles",
        accessor: "roles",
        sortType: "basic",
        Filter: (props) => (
          <SelectFilter
            label="Roles"
            options={[
              { value: "ROLE_ADMIN", label: "Admin" },
              { value: "ROLE_USER", label: "User" },
            ]}
            isMulti={true}
            {...props}
          />
        ),
        selectable: true,
        Cell: (row) =>
          row.cell.value.map((val) => (
            <span
              key={`role-${row.row.original.id}-${val}`}
              className={classNames("badge", "badge-pill", "mr-1", {
                "badge-info": val === "ROLE_ADMIN",
                "badge-success": val === "ROLE_USER",
              })}
            >
              {roleValues[val]}
            </span>
          )),
        show: selectedColumns["roles"],
      },
      {
        Header: "Created",
        accessor: "createdAt",
        sortType: "basic",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const validDate = !!date.getDate() === true;

          return value && validDate ? (
            <TimeAgo title={formatDate(new Date(value))} date={value} />
          ) : (
            "-"
          );
        },
        Filter: (props) => <DateRangeFilter label="Created" {...props} />,
        selectable: true,
        show: selectedColumns["createdAt"],
      },
      {
        Header: "Last login",
        accessor: "lastLoggedInAt",
        sortType: "basic",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const validDate = !!date.getDate() === true;

          return value && validDate ? (
            <TimeAgo title={formatDate(new Date(value))} date={value} />
          ) : (
            "-"
          );
        },
        Filter: (props) => <DateRangeFilter label="Last login" {...props} />,
        selectable: true,
        show: selectedColumns["lastLoggedInAt"],
      },
      {
        Header: "Telephone",
        accessor: "telephone",
        sortType: "basic",
        Cell: ({ cell: { value } }) => (value ? value : "-"),
        Filter: (props) => (
          <TextFilter label="Telephone" type="tel" {...props} />
        ),
        selectable: true,
        show: selectedColumns["telephone"],
      },

      {
        Header: "LKQ User ID",
        accessor: "lkqUserId",
        sortType: "basic",
        Filter: (props) => (
          <TextFilter label="LKQ User ID" maxLength={20} {...props} />
        ),
        Cell: (row) => row.cell.value || "-",
        selectable: true,
      },

      {
        Header: "Excluded from Omnirewards",
        accessor: "isExcludedFromOmnirewards",
        sortType: "basic",
        Filter: (props) => (
          <SelectFilter
            label="Excluded from Omnirewards"
            options={[
              { value: "1", label: "Yes" },
              { value: "0", label: "No" },
            ]}
            {...props}
          />
        ),
        Cell: ({ cell: { value } }) => (
          <FontAwesomeIcon
            icon={!!value ? faCheck : faTimes}
            className={!!value ? "text-success" : "text-danger"}
          />
        ),
        selectable: true,
        disableSorting: true,
        show: selectedColumns["isExcludedFromOmnirewards"],
      },
      {
        Header: () => (
          <span className="inline-block w-full text-right">Login as User</span>
        ),
        id: "loginAsUser",
        disableSorting: true,
        accessor: "firstName",
        sortType: "basic",
        Cell: (row) => {
          const id = row.row.original.originId;
          const userLoginBeingCreated = creatingUserLogin === id;
          return (
            <div className="text-right">
              <button
                className="btn btn-sm btn-outline-primary"
                disabled={!!creatingUserLogin}
                onClick={(e) => {
                  e.preventDefault();
                  onCreateLoginAsUser(id);
                }}
              >
                <FontAwesomeIcon
                  icon={userLoginBeingCreated ? faSpinner : faUser}
                  {...(userLoginBeingCreated && {
                    spin: true,
                  })}
                  className="mr-2"
                />
                Login
              </button>
            </div>
          );
        },
        selectable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creatingUserLogin, formatDate, selectedColumns]
  );

  const onFetchData = (options) => {
    let isExcludedFromOmnirewards = null;

    if (options?.filter.hasOwnProperty("isExcludedFromOmnirewards")) {
      isExcludedFromOmnirewards =
        options.filter.isExcludedFromOmnirewards === "1";
    }

    return client(GET_LIST, "users", {
      ...options,
      filter: {
        ...options.filter,
        ...(isExcludedFromOmnirewards !== null && {
          isExcludedFromOmnirewards,
        }),
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Users | Peracto</title>
      </Helmet>
      <Heading name="Users">
        <div className="ml-2 d-flex align-items-center">
          {user?.exportUserData === true && (
            <Link className="mr-2 btn btn-outline-primary" to="/users/export">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Export User Data
            </Link>
          )}

          <Link
            className="btn btn-primary"
            to="/users/add"
            data-testid="add-user"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add User
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  firstName: "",
                  lastName: "",
                  email: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <LoginAsUserModal
        showModal={showUserLoginModal}
        closeModal={() => {
          setShowUserLoginModal(false);
          setUserLoginModalFields({
            email: "",
            password: "",
          });
        }}
        fields={userLoginModalFields}
      />
    </>
  );
};
